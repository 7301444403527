import { Button } from '@finn/design-system';
import { Image } from '@finn/ui-components';
import { cn } from '@finn/ui-utils';
import { makeStyles } from '@material-ui/core';
import React, { useCallback } from 'react';

import PDPTracking from '~/components/ProductDetails/utils/PDPTracking';

import { REFERENCE_IMAGE_HEIGHT, REFERENCE_IMAGE_WIDTH } from './constants';
// TODO design-system: refactor !important styles after migration to new typography APP-1443

type Props = {
  imageUrls: string[];
  imageSrcSets: string[];
  labelsBar: React.ReactNode;
  showDisclaimer?: boolean;
  disclaimerText: string;
  fullScreen: boolean;
  setFullScreen: Function;
  setInitialSlide: Function;
  altText?: string;
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'sticky',
    top: theme.spacing(1),
  },
  gridContainer: {
    display: 'grid',
    gridGap: theme.spacing(2.5),
    gridTemplateColumns: '1fr 1fr',
  },
  gridImage: {
    width: '100%',
    height: 'auto',
    cursor: 'pointer',
    minHeight: theme.spacing(10),
    mixBlendMode: 'multiply',
  },
  firstImageBox: {
    gridColumn: '1/3',
    '&:focus-visible': {
      boxShadow: theme.shadows[2],
    },
    position: 'relative',
  },
  followingImageBox: {
    '&:focus-visible': {
      boxShadow: theme.shadows[2],
    },
    width: '100%',
  },
  labelsBar: {
    position: 'absolute',
    top: theme.spacing(0),
    left: theme.spacing(2),
    right: 0, // we set right to 0 to allow absolute element to grow as much as content inside
    [theme.breakpoints.up('sm')]: {
      top: theme.spacing(2),
    },
  },
  counterBar: {
    position: 'absolute',
    borderRadius: '2px',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  disclaimerBar: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    textAlign: 'right',
    padding: theme.spacing(2, 10, 2, 2),
  },
}));
const ImageGalleryDesktop: React.FC<Props> = ({
  imageUrls,
  imageSrcSets,
  labelsBar,
  showDisclaimer,
  disclaimerText,
  fullScreen,
  setFullScreen,
  setInitialSlide,
  altText = 'car',
}) => {
  const classes = useStyles();

  const onImageClicked = useCallback(
    (index: number) => () => {
      if (!fullScreen) {
        setInitialSlide(index);
        setFullScreen(true);
        if (index === 0) {
          PDPTracking.primaryImageClicked();
        } else {
          PDPTracking.secondaryImagesClicked();
        }
      }
    },
    [fullScreen, setFullScreen, setInitialSlide]
  );

  const getImageClassName = (index: number) =>
    index === 0
      ? cn(classes.firstImageBox, '!bg-snow')
      : cn(classes.followingImageBox, '!bg-snow');

  const images = imageUrls.slice(0, 5).map((imageUrl, index) => (
    <Button
      key={index}
      variant="ghost"
      className={cn(getImageClassName(index), 'h-max w-full p-0')}
      onClick={onImageClicked(index)}
    >
      {index === 0 && (
        <>
          <div className={classes.labelsBar}>{labelsBar}</div>
          <div
            className={cn(
              classes.counterBar,
              'body-12-medium border-pearl border border-solid bg-white px-2 py-3'
            )}
          >
            1 / {imageUrls?.length}
          </div>
          {showDisclaimer && (
            <div className={cn(classes.disclaimerBar, 'body-12-regular mb-1')}>
              {disclaimerText}
            </div>
          )}
        </>
      )}
      <Image
        className={classes.gridImage}
        src={imageUrl}
        srcSet={imageSrcSets[index]}
        width={REFERENCE_IMAGE_WIDTH}
        height={REFERENCE_IMAGE_HEIGHT}
        alt={altText}
      />
    </Button>
  ));

  return (
    <div className={classes.root}>
      <div className={classes.gridContainer}>{images}</div>
    </div>
  );
};
export default ImageGalleryDesktop;
