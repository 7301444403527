import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalHeaderVariant,
} from '@finn/atoms';
import {
  ContractDuration,
  DistanceOptions,
} from '@finn/auto-ui/components/ConfigurePage';
import { useConfigureStore } from '@finn/auto-ui/components/ConfigurePage/ConfigurePageStore';
import { ContinueButton } from '@finn/auto-ui/components/ConfigurePage/ContinueButton';
import { useViewportHeight } from '@finn/auto-ui/hooks/useViewportHeight';
import { Button } from '@finn/design-system';
import { KeyboardArrowLeft } from '@finn/design-system/icons/keyboard-arrow-left';
import { Features, useIsABVariant, useIsCVariant } from '@finn/ua-featureflags';
import { interactionTrackingEvent, TrackingEventName } from '@finn/ua-tracking';
import { VehicleContext } from '@finn/ua-vehicle';
import { getForBusinessCookie, usePreDiscountRawValue } from '@finn/ui-modules';
import { isMobileApp, useFormattedPrice, useIsMobile } from '@finn/ui-utils';
import { makeStyles, Theme } from '@material-ui/core';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { useMonthlySubscriptionCost } from '../PriceBreakdown/hooks/useMonthlySubscriptionCost';
import { ConfigureVehicleHeader } from './ConfigureVehicleHeader';
import { DownpaymentOptionsModal } from './DownpaymentModal';
import { DownPaymentSection } from './DownPaymentSection';
import { PriceDisplay } from './PriceDisplay';

// TODO design-system: refactor !important styles after migration to new typography APP-1443
const useStyles = makeStyles((theme: Theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3, 10, 3),
    },
  },

  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  buttonBox: {
    width: '50%',
  },
  mobileApp: {
    '& .MuiPaper-root > .MuiDialogTitle-root': {
      display: 'none !important',
    },
    '& .MuiPaper-root > .MuiButtonBase-root': {
      display: 'none !important',
    },
    '& .MuiPaper-root > .MuiDialogActions-root': {
      paddingBottom: '32px !important',
    },
  },
}));

enum ConfigureSteps {
  DownPaymentStep = 1,
  DistanceOptionsStep = 2,
}

type ConfigureVehicleModalProps = {
  open: boolean;
  onClose: () => void;
  loadingEdit?: boolean;
  handleEditConfig?: () => void;
};

export const ConfigureVehicleModal: React.FC<ConfigureVehicleModalProps> = ({
  open,
  onClose,
  handleEditConfig,
  loadingEdit,
}) => {
  const intl = useIntl();
  const isApp = isMobileApp();
  const classes = useStyles();
  const isMobile = useIsMobile();
  const vh = useViewportHeight();

  const { vehicle } = useContext(VehicleContext);

  const {
    term,
    onContinue,
    downPaymentAmount,
    downPayment: isDownPayment,
    isLoading: isLoadingFromStore,
  } = useConfigureStore();

  const [isFakeDoorOpen, setIsFakeDoorOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(
    ConfigureSteps.DownPaymentStep
  );

  const isMultiStep = useIsABVariant(Features.ExpPCPMultipleSteps);
  const isBVariant = useIsABVariant(Features.ExpDownPaymentOptions);
  const isCVariant = useIsCVariant(Features.ExpDownPaymentOptions);

  const isExpDownPayment = isBVariant || isCVariant;

  const isCheckoutEditModal = Boolean(handleEditConfig);
  const isForBusiness = getForBusinessCookie();

  const { total, monthlyCost } = useMonthlySubscriptionCost(vehicle);
  const preDiscountPrice = usePreDiscountRawValue(
    vehicle,
    monthlyCost,
    term,
    isForBusiness,
    isDownPayment
  );

  const originalPriceFormatted = useFormattedPrice(preDiscountPrice || 0, 0);
  const finalPriceFormatted = useFormattedPrice(total, 0);

  const subtitle = intl.formatMessage({
    id: `pdp.price.${isForBusiness ? 'b2b' : 'b2c'}_vat`,
  });

  const isShortPreDiscountPrice = preDiscountPrice?.toString().length === 4;

  const handleModalClose = useCallback(() => {
    interactionTrackingEvent(TrackingEventName.MODAL_CLOSED, {
      name: 'configure',
    });
    onClose();
  }, [onClose]);

  const handleFinalStep = useCallback(() => {
    if (isExpDownPayment && downPaymentAmount > 1200) {
      interactionTrackingEvent(TrackingEventName.MODAL_OPENED, {
        name: 'downpayment options',
      });
      setIsFakeDoorOpen(true);
    } else {
      onContinue();
    }
  }, [isExpDownPayment, downPaymentAmount, onContinue]);

  const handleMultiStep = useCallback(() => {
    switch (currentStep) {
      case ConfigureSteps.DownPaymentStep:
        setCurrentStep(ConfigureSteps.DistanceOptionsStep);
        break;
      case ConfigureSteps.DistanceOptionsStep:
        handleFinalStep();
        break;
      default:
        onContinue();
    }
  }, [currentStep, handleFinalStep, onContinue]);

  const handleContinue = useCallback(() => {
    if (isMultiStep) {
      handleMultiStep();
    } else {
      handleFinalStep();
    }
  }, [isMultiStep, handleMultiStep, handleFinalStep]);

  const handleBack = useCallback(() => {
    if (isMultiStep && currentStep > ConfigureSteps.DownPaymentStep) {
      setCurrentStep(ConfigureSteps.DownPaymentStep);
    }
  }, [isMultiStep, currentStep]);

  useEffect(() => {
    if (isMultiStep) {
      interactionTrackingEvent(TrackingEventName.CTA_CLICKED, {
        name: `Configure step - ${currentStep}`,
      });
    }
  }, [currentStep, isMultiStep]);

  return (
    <Modal
      fullScreen
      open={open}
      type="alert"
      calculatedVh={vh}
      disableBrowserHistory
      onClose={handleModalClose}
      hasDesktopTransition={!isMobile}
      variant={ModalHeaderVariant.SMALL}
      className={isApp ? classes.mobileApp : undefined}
    >
      {/* HERE we need to add back button for multi step we can remove it later
          TODO: design-system: Fix ModalHeader component to support back button and the close button is not aligned
       */}
      <div className="relative">
        <ModalHeader variant={ModalHeaderVariant.SMALL}>
          <div className="flex items-center ">
            {isMultiStep && currentStep > ConfigureSteps.DownPaymentStep && (
              <Button
                className="absolute left-1 top-2 z-10 flex items-center justify-center"
                variant="ghost"
                onClick={handleBack}
                size="md"
              >
                <KeyboardArrowLeft
                  width={24}
                  height={24}
                  onClick={handleBack}
                />
              </Button>
            )}
            <div className="xs:ml-12">
              {intl.formatMessage({ id: 'pdp.configure.generalTitle' })}
            </div>
          </div>
        </ModalHeader>
      </div>
      <ModalContent className={classes.content}>
        <ConfigureVehicleHeader vehicle={vehicle} />

        {isMultiStep ? (
          <>
            {currentStep === ConfigureSteps.DownPaymentStep && (
              <>
                <DownPaymentSection
                  term={term}
                  isExpDownPayment={isExpDownPayment}
                  isCheckoutEditModal={isCheckoutEditModal}
                />
                <ContractDuration />
              </>
            )}
            {currentStep === ConfigureSteps.DistanceOptionsStep && (
              // Appear from left to right
              <div className="animate-slide-in-from-left flex flex-col items-start">
                <DistanceOptions />
              </div>
            )}
          </>
        ) : (
          <>
            <DownPaymentSection
              term={term}
              isExpDownPayment={isExpDownPayment}
              isCheckoutEditModal={isCheckoutEditModal}
            />
            <ContractDuration />
            <DistanceOptions />
          </>
        )}
      </ModalContent>

      <ModalFooter>
        <div className={classes.footer} translate="no">
          <PriceDisplay
            subtitle={subtitle}
            finalPrice={finalPriceFormatted}
            originalPrice={originalPriceFormatted}
            isShortPrice={isShortPreDiscountPrice}
            hasOriginalPrice={Boolean(preDiscountPrice)}
          />

          <div className={classes.buttonBox}>
            <ContinueButton
              isInPDP
              testId="submit-config"
              onClick={handleContinue}
              isLoading={isLoadingFromStore || loadingEdit}
            />
          </div>
        </div>

        <DownpaymentOptionsModal
          isOpen={isFakeDoorOpen}
          onClose={() => setIsFakeDoorOpen(false)}
        />
      </ModalFooter>
    </Modal>
  );
};
