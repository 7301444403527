import { ProductOptionCard } from '@finn/platform-modules';
import { Features, useIsABVariant } from '@finn/ua-featureflags';
import { getFormattedNumber, getFormattedPrice } from '@finn/ui-utils';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

import PDPTracking from '~/components/ProductDetails/utils/PDPTracking';
import { useTrackingData } from '~/components/ProductDetails/utils/useTrackingData';
import { MonthlyPrice } from '~/contexts/Vehicle';

interface IProps {
  monthlyPrice: MonthlyPrice;
  distanceUnit: string;
  isSelected: boolean;
  onClick(monthlyPrice: MonthlyPrice): void;
}

const DistanceOptionsItem: React.FunctionComponent<IProps> = ({
  monthlyPrice,
  distanceUnit,
  isSelected,
  onClick,
}) => {
  const i18n = useIntl();
  const isBVariantNoTogglePrices = useIsABVariant(
    Features.ExperimentPCPNoTogglePrices
  );

  const title = `${getFormattedNumber(
    i18n,
    monthlyPrice.distance
  )} ${distanceUnit}`;

  const description =
    monthlyPrice.price > 0
      ? `+ ${getFormattedPrice(i18n, monthlyPrice.price, 0)}`
      : i18n.formatMessage({ id: 'pdp.free' });
  const recommendedLabel = i18n.formatMessage({ id: 'pdp.recommended' });
  const trackingData = useTrackingData();

  const trackDistanceClicked = useCallback(
    (distance: string) => {
      PDPTracking.distanceClicked({
        ...trackingData,
        distancepackage: distance,
      });
    },
    [trackingData]
  );

  const handleClick = useCallback(() => {
    trackDistanceClicked(title);
    onClick(monthlyPrice);
  }, [trackDistanceClicked, title, onClick, monthlyPrice]);

  const isRecommended = title.includes('1.500 km');

  return (
    <ProductOptionCard
      title={title}
      label={isRecommended && recommendedLabel}
      desc={!isBVariantNoTogglePrices && description}
      isSelected={isSelected}
      onClick={handleClick}
    />
  );
};

export default React.memo(DistanceOptionsItem);
