import { Button } from '@finn/design-system';
import { Close } from '@finn/design-system/icons/close';
import { Image } from '@finn/ui-components';
import { ArrowDirection } from '@finn/ui-modules';
import { cn, CookieKeys } from '@finn/ui-utils';
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import Cookies from 'js-cookie';
import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';

import PinchZoomSlide from '~/components/ProductDetails/ImageGallery/PinchZoomSlide';
import PDPTracking from '~/components/ProductDetails/utils/PDPTracking';

import ImageGalleryArrow from './ImageGalleryArrow';

type Props = {
  imageUrls: string[];
  imageThumbUrls: string[];
  imageSrcSets: string[];
  labelsBar: React.ReactNode;
  showDisclaimer?: boolean;
  disclaimerText: string;
  fullScreen: boolean;
  setFullScreen: Function;
  initialSlide: number;
  isMobile: Boolean;
  altText?: string;
  pdpDrawer?: boolean;
};

type StyledProps = {
  fullScreen: boolean;
  isRibbonDismissed: boolean;
  pdpDrawer: boolean;
};

const useStyles = makeStyles((theme) => ({
  sliderWrapper: {
    display: 'flex',
    width: ({ pdpDrawer }: StyledProps) =>
      pdpDrawer ? 'calc(100% + 32px)' : '100%',
    marginLeft: ({ pdpDrawer }: StyledProps) =>
      pdpDrawer ? -theme.spacing(2) : 0,
    [theme.breakpoints.up('sm')]: {
      width: ({ pdpDrawer }: StyledProps) =>
        pdpDrawer ? 'calc(100% + 160px)' : '100%',
      marginLeft: ({ pdpDrawer }: StyledProps) =>
        pdpDrawer ? -theme.spacing(10) : 0,
    },
    height: (props: StyledProps) => (props.fullScreen ? '100vh' : 'auto'),
    position: (props: StyledProps) => (props.fullScreen ? 'fixed' : 'relative'),
    top: (props: StyledProps) => (props.fullScreen ? 0 : null),
    left: (props: StyledProps) => (props.fullScreen ? 0 : null),
  },
  fullScreen: {
    opacity: 0,
    transform: 'translateY(30%)',
    transition: '0.3s opacity, 0.3s transform',
    zIndex: theme.customZIndices.pdpFullscreenGallery,
  },
  loaded: {
    opacity: 1,
    transform: 'none',
  },
  sliderRoot: {
    width: '100%',
    alignSelf: 'center',
    '& .slick-track': {
      display: 'flex',
      alignItems: 'center',
    },
    '& .slick-slider': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    '& .slick-list': {
      width: '100%',
    },
    marginTop: 0,
    marginBottom: (props: StyledProps) => (props.fullScreen ? 88 : 0),

    [theme.breakpoints.up('sm')]: {
      marginTop: (props: StyledProps) => (props.fullScreen ? 88 : 0),
      marginBottom: 0,
    },
  },
  imageSlider: {
    mixBlendMode: 'multiply',
    objectFit: 'contain',
    width: '100%',
    minWidth: 320,
    minHeight: 220,
    alignItems: 'center',
    height: (props: StyledProps) =>
      props.fullScreen ? 'calc(100vh - 88px)' : 'auto',
    maxHeight: (props: StyledProps) => (props.fullScreen ? '100vh' : 400),
    [theme.breakpoints.up('sm')]: {
      maxHeight: ({ fullScreen, pdpDrawer }: StyledProps) =>
        fullScreen ? '100vh' : pdpDrawer ? 320 : 270,
    },
  },
  labelsBar: {
    position: 'absolute',
    display: (props: StyledProps) => (props.fullScreen ? 'none' : null),
    top: theme.spacing(1),
    left: theme.spacing(1),
    right: 0, // we set right to 0 to allow absolute element to grow as much as content inside
    [theme.breakpoints.up('sm')]: {
      top: theme.spacing(3),
    },
  },
  counterBar: {
    position: 'absolute',
    right: (props: StyledProps) =>
      props.fullScreen ? '50%' : theme.spacing(1),
    transform: (props: StyledProps) =>
      props.fullScreen ? 'translateX(50%)' : null,
    bottom: (props: StyledProps) =>
      props.fullScreen ? theme.spacing(14.25) : theme.spacing(1),
    borderRadius: '2px',
    [theme.breakpoints.up('sm')]: {
      bottom: (props: StyledProps) =>
        props.fullScreen ? theme.spacing(8.5) : theme.spacing(1),
    },
  },
  disclaimerText: {
    position: 'absolute',
    bottom: 0,
    right: theme.spacing(8),
    maxWidth: '80%',
    textAlign: 'right',
  },
  closeButton: {
    position: 'absolute',
    zIndex: theme.customZIndices.pdpFullscreenGallery,
    top: theme.spacing(13),
    right: theme.spacing(1),
    transform: 'scale(0.75)',
    [theme.breakpoints.down('md')]: {
      top: (props: StyledProps) =>
        props.isRibbonDismissed ? theme.spacing(13) : theme.spacing(21),
    },
  },
  thumb: {
    maxWidth: '100%',
  },
  slickDots: {
    order: 2,
    bottom: 0,
    margin: 0,
    height: theme.spacing(12),
    marginLeft: -40,
    listStyle: 'none',
    display: 'flex !important',
    alignItems: 'center',
    justifyContent: 'center',
    '& a': {
      display: 'inline-block',
    },
    '& li': {
      maxWidth: '88px',
      padding: theme.spacing(0, 1),
      display: 'inline-block',
      cursor: 'pointer',
      '& img': {
        maxWidth: '100%',
      },
    },
    '& .slick-active': {
      '& img': {
        maxWidth: '100%',
      },
    },
  },
}));
const ImageGalleryMobile: React.FC<Props> = ({
  imageUrls,
  imageThumbUrls,
  imageSrcSets,
  labelsBar,
  showDisclaimer,
  disclaimerText,
  fullScreen,
  setFullScreen,
  initialSlide,
  isMobile,
  pdpDrawer = false,
  altText = 'car',
}) => {
  const isRibbonDismissed = !!Cookies.get(CookieKeys.RIBBON_DISMISSED);
  const classes = useStyles({ fullScreen, isRibbonDismissed, pdpDrawer });
  const [sliderCurrentIndex, setSliderCurrentIndex] = useState(initialSlide);
  const [loaded, setLoaded] = useState(false);
  const totalImageCount = imageUrls?.length;
  const sliderRef = useRef<Slider>(null);

  useEffect(() => {
    sliderRef.current?.slickGoTo(initialSlide, true);
  }, [initialSlide]);
  useEffect(() => {
    setTimeout(() => setLoaded(fullScreen), 100);
  }, [fullScreen]);

  const onKeyboardNav = (e) => {
    if (e?.key === 'ArrowRight') {
      sliderRef?.current?.slickNext?.();
      PDPTracking.nextArrowClicked();
    } else if (e?.key === 'ArrowLeft') {
      sliderRef?.current?.slickPrev?.();
      PDPTracking.previousArrowClicked();
    } else if (e?.key === 'Escape') {
      setFullScreen(false);
      PDPTracking.galleryCloseClicked();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', onKeyboardNav);

    return () => window.removeEventListener('keydown', onKeyboardNav);
  }, []);

  const sliderSettings = {
    dots: !isMobile,
    arrows: fullScreen,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide,
    afterChange: (index: number) => {
      setSliderCurrentIndex(index);
    },
    prevArrow: <ImageGalleryArrow direction={ArrowDirection.left} />,
    nextArrow: <ImageGalleryArrow direction={ArrowDirection.right} />,
    onSwipe: (direction: string) => {
      if (direction === 'right') {
        PDPTracking.previousSwipe();
      } else {
        PDPTracking.nextSwipe();
      }
    },
    // eslint-disable-next-line react/display-name
    customPaging: (i: number) => (
      <a onClick={PDPTracking.thumbnailsClicked}>
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
        <Image src={imageThumbUrls[i]} alt="thumb" className={classes.thumb} />
      </a>
    ),
    dotsClass: cn(
      classes.slickDots,
      'bg-white [&_li_img]:border [&_li_img]:border-pearl [&_li_img]:border-solid [&_.slick-active_img]:border-black [&_.slick-active_img]:border-2'
    ),
  };

  const rootClassName = classNames(classes.sliderWrapper, 'bg-white', {
    [classes.fullScreen]: fullScreen,
    [classes.loaded]: loaded,
  });

  return (
    <div
      className={rootClassName}
      data-appid={`image-gallery-mobile-${fullScreen ? 'fullScreen' : ''}`}
    >
      {fullScreen && (
        <Button
          variant="ghost"
          data-appid="image-gallery-mobile-close-button"
          className={cn(classes.closeButton, 'h-max rounded-full p-2')}
          onClick={() => {
            setFullScreen(false);
            PDPTracking.galleryCloseClicked();
          }}
        >
          <Close />
        </Button>
      )}
      <div
        className={cn(classes.sliderRoot, {
          'bg-snow': !fullScreen,
          'bg-white': fullScreen,
        })}
        onClick={() => {
          if (!fullScreen) {
            setFullScreen(true);
            PDPTracking.primaryImageClicked();
          }
        }}
      >
        <Slider ref={sliderRef} {...sliderSettings}>
          {imageUrls.map((picture, i) => (
            <Button
              key={picture}
              variant="ghost"
              className={classNames(
                'h-full w-full p-0 before:hidden after:hidden',
                {
                  'bg-snow': !fullScreen,
                }
              )}
            >
              <PinchZoomSlide
                url={picture}
                srcSet={imageSrcSets[i]}
                className={classes.imageSlider}
                fullScreen={fullScreen}
                altText={altText}
                isFirst={i === 0}
              />
            </Button>
          ))}
        </Slider>
      </div>

      <div className={classes.labelsBar}>{labelsBar}</div>
      {isMobile && (
        <>
          <div
            className={cn(
              classes.counterBar,
              'body-12-medium bg-white px-2 py-2',
              {
                'border-pearl border border-solid': !fullScreen,
              }
            )}
          >
            {sliderCurrentIndex + 1} / {totalImageCount}
          </div>

          {showDisclaimer && !fullScreen && (
            <div className={cn(classes.disclaimerText, 'body-12-regular mb-1')}>
              {disclaimerText}
            </div>
          )}
        </>
      )}
    </div>
  );
};
export default ImageGalleryMobile;
