import { DownPayment } from '@finn/auto-ui/components/ConfigurePage/DownPayment/DownPayment';
import { DownPaymentExperiment } from '@finn/auto-ui/components/ConfigurePage/DownPayment/DownPaymentExperiment';
import React from 'react';

type DownPaymentSectionProps = {
  term: number;
  isExpDownPayment: boolean;
  isCheckoutEditModal: boolean;
};

export const DownPaymentSection: React.FC<DownPaymentSectionProps> = ({
  isCheckoutEditModal,
  isExpDownPayment,
  term,
}) => {
  if (isCheckoutEditModal) {
    return null;
  }

  if (isExpDownPayment && term > 1) {
    return <DownPaymentExperiment />;
  }

  return <DownPayment />;
};
