import { ContinueButtonLocation } from '@finn/auto-ui/types/pdp';
import { Button } from '@finn/design-system';
import { interactionTrackingEvent, TrackingEventName } from '@finn/ua-tracking';
import { cn } from '@finn/ui-utils';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

// scroll position of top-left corner in range [0...1]
const getCTAScrollPosition = () =>
  Math.round(
    (window.scrollY /
      (document.documentElement.scrollHeight - window.innerHeight)) *
      10000
  ) / 10000;

interface IProps {
  location?: ContinueButtonLocation;
  buttonClassName?: string;
  onClick: () => void;
  isLoading: boolean;
  testId?: string;
}
export const ContinueButton: React.FunctionComponent<IProps> = ({
  location,
  buttonClassName,
  isLoading,
  onClick,
  testId,
}) => {
  const i18n = useIntl();

  const handleClick = useCallback(() => {
    if (location) {
      interactionTrackingEvent(TrackingEventName.CTA_CLICKED, {
        location,
        scrollPosition: getCTAScrollPosition(),
      });
    }

    onClick();
  }, [location, onClick]);

  const title = i18n.formatMessage({
    id: `pdp.configureBeforeBooking2`,
  });

  return (
    <Button
      className={cn('w-full', buttonClassName)}
      loading={isLoading}
      testId={testId || 'submit'}
      disabled={isLoading}
      onClick={handleClick}
    >
      {title}
    </Button>
  );
};
