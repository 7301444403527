import cn from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useCallback, useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';

import PDPTracking from '~/components/ProductDetails/utils/PDPTracking';
import { useTrackingData } from '~/components/ProductDetails/utils/useTrackingData';
import VehicleContext, { VehicleColor } from '~/contexts/Vehicle';

const VehiclesColor: React.FunctionComponent = () => {
  const contextData = useContext(VehicleContext);
  const vehicle = contextData.vehicle;
  const i18n = useIntl();
  const { pathname } = useRouter();

  const categoryBaseUrl = useMemo(
    () => pathname.substring(0, pathname.lastIndexOf('/')),
    [pathname]
  );

  const availableColors = (vehicle?.color_list ?? []).filter(
    (color: VehicleColor) =>
      color.availability || vehicle?.coming_soon || color.car_id === vehicle.id
  );

  const trackingData = useTrackingData();

  const trackColorChange = useCallback(
    (color: string) => {
      PDPTracking.colorClicked({ ...trackingData, color });
    },
    [trackingData]
  );

  return (
    <>
      <h6 className="global-t6-semibold flex min-w-28">
        {i18n.formatMessage({
          id: 'pdp.selectedColor',
          defaultMessage: 'Color',
        })}
        {' - '}
        {vehicle?.color?.specific}
      </h6>
      <div className="flex flex-wrap items-center justify-start">
        {availableColors.map((color: VehicleColor) => (
          <Link href={`${categoryBaseUrl}/${color.car_id}`} key={color.car_id}>
            <a
              title={color.color_specific}
              className={cn(
                'group mr-4 mt-4 flex cursor-pointer items-center justify-center rounded-full border-2 border-solid outline-none',
                {
                  'border-black': color.car_id === vehicle.id,
                  'hover:border-iron focus:border-iron border-white':
                    color.car_id !== vehicle.id,
                }
              )}
            >
              <div
                className={cn(
                  'border-pearl h-8 w-8 rounded-full border border-solid group-hover:border-[3px] group-focus:border-[3px]'
                )}
                style={{ backgroundColor: color.color_hex }}
                onClick={() => trackColorChange(color.color_specific)}
              />
            </a>
          </Link>
        ))}
      </div>
    </>
  );
};

export default VehiclesColor;
