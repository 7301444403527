import { makeStyles, Theme } from '@material-ui/core';
import React from 'react';

type PriceDisplayProps = {
  subtitle: string;
  finalPrice: string;
  originalPrice: string;
  isShortPrice: boolean;
  hasOriginalPrice: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  priceSection: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: theme.spacing(1),
    padding: theme.spacing(1, 0),
    [theme.breakpoints.up('sm')]: {
      gap: theme.spacing(2),
      padding: 0,
    },
  },
  price: {
    display: 'flex',
    gap: theme.spacing(1),
  },
  strikeThrough: {
    textDecoration: 'line-through',
  },
}));

export const PriceDisplay: React.FC<PriceDisplayProps> = ({
  originalPrice,
  finalPrice,
  subtitle,
  isShortPrice,
  hasOriginalPrice,
}) => {
  const classes = useStyles();

  const priceClass = isShortPrice
    ? 'sm:mobile-t4-semibold md:web-t4-semibold'
    : 'sm:mobile-t3-semibold md:web-t3-semibold';

  return (
    <div className={classes.priceSection}>
      <div className={classes.price}>
        {hasOriginalPrice && (
          <span
            className={`global-t5-semibold ${priceClass} !text-iron ${classes.strikeThrough}`}
          >
            {originalPrice}
          </span>
        )}
        <span className={`global-t5-semibold ${priceClass} !text-black`}>
          {finalPrice}
        </span>
      </div>
      <span className="body-14-light">{subtitle}</span>
    </div>
  );
};
